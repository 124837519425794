@import "../../styles/variables";

:local {
  .feature:not(:last-child)::after {
    content: "";
    margin: 0 5px;
  }
  .stepImg {
    width: 150px;
    margin: auto;
  }

  @mixin set-as-column-mixin() {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    position: relative;
    width: 100%;
  }
  @mixin set-as-row-mixin() {
    display: flex;
    flex-wrap: wrap;
  }

  $icon-container-width-mobile: 65px;
  $text-container-width-mobile: calc(100% - 65px);
  $icon-container-height-desktop: 69px;
  $text-container-height-desktop: calc(100% - 69px);

  div.howItWorksSteps {
    @include set-as-column-mixin();
  }
  div.howItWorksStep {
    @include set-as-row-mixin();
  }
  div.howItWorksIconContainer {
    width: $icon-container-width-mobile;
    text-align: left;
    display: inline-block;
  }
  div.howItWorksTextContainer {
    width: $text-container-width-mobile;
    text-align: left;
    display: inline-block;
  }
  .howItWorksStepTitle {
    font-weight: bold;
  }
  .howItWorksStepDescription {
    color: #666666;
  }

  .curvedArrow {
    display: None;
  }

  p.subtitle {
    font-size: 22px;
    color: white;
    font-weight: normal;
  }

  /*
    Desktop Styles
   */
  @include media-breakpoint-up(lg) {
    .featureGroup {
      display: inline-block;
      &:not(:last-child)::after {
        content: "∙";
        margin: 0 5px;
      }
    }
    .feature {
      font-size: 1.5rem;
    }
    .stepImg {
      width: 100%;
    }
    div.howItWorksSteps {
      @include set-as-row-mixin();
    }
    div.howItWorksStep {
      @include set-as-column-mixin();
    }
    div.howItWorksIconContainer {
      width: 100%;
      display: block;
      height: $icon-container-height-desktop;
    }
    div.howItWorksTextContainer {
      width: 100%;
      text-align: center;
      display: block;
    }

    .curvedArrow {
      display: inline-block;
    }

    .flippedY {
      transform: scaleY(-1);
    }
  }
}
