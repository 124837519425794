@use "sass:map";
@import "../../../styles/variables.scss";

.container {
    padding-top: 30px;
    padding-bottom: 50px;
    max-width: 450px;
    margin-right: auto;
    margin-left: auto;

    div[role="listbox"] {
        z-index: 105;
    }
}

.header {
    font-family: $font-family-sans-serif;
    display: block;
    margin-bottom: 20px;

    span {
        display: block;
        text-align: center;
    }

    .title {
        font-size: 20px;
        list-style: 26px;
        font-weight: 700;
        color: var(--jetline-black);
    }

    .subtitle {
        font-size: 12px;
        list-style: 16px;
        font-weight: 400;
        color: var(--asphalt);
    }
}

.formFields {
    display: flex;
    flex-direction: column;
    gap: 17px;
}

.helpText {
    display: block;
    margin-top: 10px;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    color: var(--jetline-black);
}
