:local {
  // Desktop styles
  @media (min-width: 768px) {
    .intercomElementStyle {
      right: 20px;
      bottom: 20px;
      background: var(--primary);
      width: 60px;
      height: 60px;
      border-radius: 50%;
      position: fixed;
      border: none;
      z-index: 2;
    }

    .intercomElementStickyFooterStyle {
      bottom: 116px;
    }

    .intercomSvgStyle {
      fill: var(--light);
      height: 32px;
      width: 28px;
    }

  }

  // Mobile styles
  @media (max-width: 991px) {
    .intercomElementStyle {
      right: 20px;
      bottom: 120px;
      background: var(--primary);
      width: 60px;
      height: 60px;
      border-radius: 50%;
      position: fixed;
      border: none;
      z-index: 2;
    }

    .intercomSvgStyle {
      fill: var(--light);
      height: 32px;
      width: 28px;
    }
  }

}
