@import "../../../styles/variables";

:local {
  .msrp {
    font-weight: bold;
    font-size: 1.75rem;
  }

  .priceAndActionButtonsFixed {
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    padding: 10px;
    position: fixed;
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.50);
  }

  .instantFinancingOffersButtonText {
    font-size: 17px !important;
  }

  .stickyFooterTitle {
    /* min-width 0 needed to allow text ellipsis within a col adjacent to an auto width col */
    min-width: 0;
    padding-right: 0;
    display: none;
  }

  .stickyFooterImage {
    height: 65px;
    width: 130px;
    object-fit: contain;
  }

  /*
   Tablet Styles
  */
  @include media-breakpoint-up(md) {
    .instantFinancingOffersButton {
      width: 325px;
    }
  }

  /*
   Desktop Styles
  */
  @include media-breakpoint-up(lg) {
    .priceAndActionButtonsRelative {
      padding: 10px 0;
      position: static;
      box-shadow: none;
      background-color: unset;
    }
  }

  .specText {
    font-size: 22px;
  }

  .submodelsSection {
    padding-top: 39px;
  }

  .submodelSubtitle {
    margin-bottom: 10px;
  }

}
