@import '../../../../styles/variables.scss';

.typesContainer {
    margin-bottom: 45px;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    width: 350px;
    margin: auto;

    &:not(.singleItem)::after {
        content: '';
        flex: auto;
    }

    &.singleItem {
        justify-content: center;
    }
}

.item {
    cursor: pointer;
    height: 110px;
    width: 110px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    opacity: 1;
    transition: opacity 0.3s ease;

    &.selected {
        cursor: default;
        outline: 3px solid var(--primary);
    }

    &.notSelected {
        opacity: 0.7;
    }

    span {
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        color: var(--raven-sub);
    }
}

.action {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
