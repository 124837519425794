@import "../../../styles/variables";

.slider {
    height: 40px;
}

.top {
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.priceField {
    width: 130px;
    margin-bottom: 10px;

    & > div {
        margin-bottom: 0 !important;
    }
}

.label {
    display: block;
    font-size: 18px;
    line-height: 22px;
}

.thumb {
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    color: #fff;
    background-color: var(--primary);
    border-radius: 5px;
    cursor: pointer;
    padding: 12px 7px;

    &:focus-visible {
        outline: none;
    }

    &.disabled {
        background-color: var(--concrete);
    }
}

.thumbBasic {
    padding-left: 15px;
    padding-right: 15px;
}

.track {
    height: 10px;
    border-radius: 10px;
    transform: translateY(-50%);
    top: 50%;

    &.bgSelected {
        background-color: var(--primary);
    }

    &.bgArea {
        background-color: #e6e9ef;
    }

    &.disabled {
        background-color: var(--concrete);
    }
}
