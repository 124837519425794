:local {
  .colorSwatch {
    width: 30px;
    height: 30px;
    cursor: pointer;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.50);
  }
  .chosenColor {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.50);
    border: 2px solid black;
  }
  .capitalize {
      text-transform: capitalize;
  }
}
