@use "sass:map";
@import "../../styles/variables";

:local {
  .heroContainer {
    max-width: map_get($container-max-widths, "xl");
  }

  .heroImage {
    height: 350px;
    width: 100%;
    object-fit: cover;
  }

  .heroImageMobile {
    height: 200px;
    width: 100%;
    object-fit: cover;
  }

  .makeLogo {
    max-width:172px;
  }
}
